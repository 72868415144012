import React, { useState, useEffect } from 'react';

import { Chart as ChartJS, LinearScale, RadialLinearScale, PointElement, LineElement, Filler, Tooltip } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import Icons from '../../../assets/vector/icons';
import ModalX from '../ModalX';
import GetImageAdn from "../getImageAdn";
import ChangeView from './change_view';

import cotton from '../../../assets/img/ElementsFarm/cotton.png';
import wool from '../../../assets/img/ElementsFarm/wool.png';
import leather from '../../../assets/img/ElementsFarm/leather.png';
import leaf from '../../../assets/img/ElementsFarm/leaf.png';
import linen from '../../../assets/img/ElementsFarm/linen.png';
import silk from '../../../assets/img/ElementsFarm/silk.png';

ChartJS.register(LinearScale, RadialLinearScale, PointElement, LineElement, Filler, Tooltip);

const HexStats = ({ wp, mint, element, setModalNft, setModalNftBurn, market }) => {
    const [hex_modal, setHexModal] = useState(false);


    const wp_icons = [cotton, wool, leather, leaf, linen, silk]
    const wp_name = ["Cotton", "Wool", "Leather", "Leaf", "Linen", "Silk"];
    const wp_data = wp;

    const plugins = [{
        afterDraw: chart => {
            const scale = chart.scales.r;
            for (let i = 0; i <= 5; i++) {
                drawTextAtIndex(scale, i, wp_name[i]);
            }
        }
    }];

    function drawTextAtIndex(scale, index, text) {
        const img = new Image();
        img.src = wp_icons[index];
        const offset = 36;
        const r = scale.drawingArea + offset;
        const angle = scale.getIndexAngle(index) - Math.PI / 2;
        const x = scale.xCenter + Math.cos(angle) * r;
        const y = scale.yCenter + Math.sin(angle) * r;
        const ctx = scale.ctx;
        ctx.save();
        ctx.translate(x, y);
        //ctx.rotate(angle + Math.PI / 2);
        ctx.textAlign = 'center';

        ctx.fillStyle = 'blue';
        ctx.font = '20px material-icons'
        ctx.drawImage(img, -15, -2, 30, 30);

        ctx.font = "12px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
        ctx.fillStyle = 'white';
        ctx.fillText(text, 0, -5);
        ctx.restore();
    }

    const data = {
        labels: wp_name,
        datasets: [{
            borderWidth: 1,
            data: wp_data,
            fill: true,
            backgroundColor: 'rgba(240,193,220,0.43)',
            borderColor: '#f0c1dc',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)',

        }]
    };
    const options = {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
            r: {
                pointLabels: {
                    display: false
                },
                ticks: {
                    stepSize: 15,
                    maxTicksLimit: 5,
                    display: false
                },
                grid: {
                    tickColor: '#000',
                    lineWidth: 3,
                    tickWidth: 3,
                },
                suggestedMin: 0,
                suggestedMax: 250
            }
        }
    }

    const options_modal = {
        layout: {
            padding: 80
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            r: {
                pointLabels: {
                    color: '#fff',
                    display: false
                },
                ticks: {
                    stepSize: 15,
                    maxTicksLimit: 6,
                    display: false,
                },
                grid: {
                    color: '#D4D4D4',
                    lineWidth: 3
                },
                angleLines: {
                    color: '#A9A9A9'
                },
                suggestedMin: 0,
                suggestedMax: 250
            }
        }
    }

    const open_hex_stats = (e) => {
        e.stopPropagation();
        setHexModal(true);
    }

    return (
        <>
            <div className="flex justify-center my-1">
                <Radar datasetIdKey={element.id} width={120} height={120} options={options} data={data} className="cursor-zoom-in" onClick={open_hex_stats} />
            </div>
            <ModalX id={element.id} view={hex_modal} action={setHexModal} bg={true} btn_close={true}>
                <ChangeView />
                <div className="flex items-center flex-wrap justify-center">
                    <div className="w-full md:w-16">
                        {element && !element.forSale &&
                            <div className="cursor-pointer text-white flex flex-col items-center" onClick={() => { if (!mint) { setModalNftBurn({ item: element, type: "waifu" }) }; setHexModal(false) }}>
                                <Icons.burn />
                                <div className="font-semibold">Burn</div>
                            </div>
                        }
                    </div>
                    <div className="w-full md:w-[38rem] my-8 flex justify-center">
                        <div className="w-4/5 md:w-[38rem]" id="cv_hex">
                            <Radar datasetIdKey='id' options={options_modal} data={data} plugins={plugins} />
                        </div>
                        <div className="hidden relative h-80 md:h-[40rem] w-80 md:w-[40rem] transition-all ease-in-out duration-300" id="cv_waifu">
                            <GetImageAdn genData={element?.adn || "0003000300300030030003000300030030003003000300300030030030303"} />
                        </div>
                    </div>
                    <div className="w-full md:w-16">
                        {!market &&
                            <div className="cursor-pointer text-white flex flex-col items-center" onClick={() => { if (!mint) setModalNft({ item: element, type: "waifu" }); setHexModal(false) }}>
                                <Icons.sell />
                                <div className="font-semibold">{element && !element.forSale ? "Sell" : "Unsell"}</div>
                            </div>
                        }
                    </div>
                </div>
            </ModalX>
        </>
    );
}

export default HexStats;