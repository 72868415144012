import React, {useState, useEffect} from 'react';

import Icons from '../../../assets/vector/icons';

const ChangeView = () => {
    const [change_view, setChangeView] = useState(false);

    useEffect(() => {
        if (change_view) {
            document.getElementById('cv_hex').classList.add('hidden');
            document.getElementById('cv_waifu').classList.remove('hidden');
        } else {
            document.getElementById('cv_hex').classList.remove('hidden');
            document.getElementById('cv_waifu').classList.add('hidden');
        }
    }, [change_view]);

    return (
        <div className="absolute top-5 left-5" onClick={() => setChangeView(!change_view)}>
            <button className={`flex p-1 pr-3 text-white rounded-full font-bold items-center transition-all duration-300 ${change_view ? 'bg-darkTeal' : 'bg-darkPink'}`}>
                <div className={`rounded-full w-9 h-9 flex justify-center items-center mr-2 transition-all duration-300 ${change_view ? 'text-darkTeal bg-primaryskyblue' : 'text-darkPink bg-primarypeach'}`}>
                    {change_view ? <Icons.hexagon /> : <Icons.peach />}
                </div>
                <div className="text-lg">{change_view ? 'See Hexagon' : 'See Waifu'}</div>
            </button>
        </div>
    )
}

export default ChangeView;