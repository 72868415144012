import React, { useContext, useEffect, useState } from "react"
import day from "../../assets/img/waifuBackground/day.webp"
import night from "../../assets/img/waifuBackground/night.webp"

import start1 from "../../assets/vector/startWaifu1.svg"
import start2 from "../../assets/vector/startWaifu2.svg"
import start3 from "../../assets/vector/startWaifu3.svg"
import start4 from "../../assets/vector/startWaifu4.svg"
import start5 from "../../assets/vector/startWaifu5.svg"

import burn_icon from "../../assets/vector/cards/burn.svg"
import sell_icon from "../../assets/vector/cards/sell.svg"
import unsell_icon from "../../assets/vector/cards/unsell.svg"
import ribbonIcon from "../../assets/img/ribbonIcon.svg"
import ribbonCrew from "../../assets/img/ribbonCrew.svg"
import license_icon from "../../assets/img/licenseIcon.svg"

import CardFlip from "./card_flip"

import GetImageAdn from "./getImageAdn"
import HexStats from "./HexStats"


import UserContext from '../../context/userContext/UserContext'

const WaifuCard = ({
  autoSelectState,
  selectStartWaifu,
  icon,
  handleSelectWaifu,
  selectWaifu,
  mint,
  damanger,
  inventory,
  harvesting,
  element,
  setModalNft,
  setModalNftBurn,
  market,
  buyWaifu,
  mask,
  purchasable,
  creatingCrew,
  editingCrew,
  harvestingMaterial,
  activeIcon,
  crew
}) => {
  const [haverstCard, setHarvestCard] = useState(harvesting)
  const [waifuPower, setWaifuPower] = useState(0)
  const [waifuIcon, setWaifuIcon] = useState(null)
  const [flip, setFlip] = useState(false)
  const [farmTime, setFarmTime] = useState(null)
  const [ownerSell, setOwnerSell] = useState(null)
  const { contract, account, chainId } = useContext(UserContext)
  const [crewInactive, setCrewInactive] = useState(null)

  useEffect(() => {
    let timeNow = new Date()
    let secondsLeft = element.availableFrom - timeNow.getTime() / 1000
    let intervalId

    if (secondsLeft > 0) {
      intervalId = setInterval(() => {
        if (secondsLeft > 0) {
          secondsLeft = secondsLeft - 1
          setFarmTime({
            id: element.id,
            seconds: new Date((secondsLeft || 0) * 1000)
              .toISOString()
              .substr(
                (secondsLeft || 0) < 3600 ? 14 : 11,
                (secondsLeft || 0) < 3600 ? 5 : 8
              ),
          })
        }
      }, 1000)
      setFarmTime({
        id: element.id,
        seconds: new Date((secondsLeft || 0) * 1000)
          .toISOString()
          .substr(
            (secondsLeft || 0) < 3600 ? 14 : 11,
            (secondsLeft || 0) < 3600 ? 5 : 8
          ),
      })
    } else setFarmTime(null)

    return () => clearInterval(intervalId)
  }, [element])

  useEffect(() => {


    if (element.id) {


      contract.ContractCrew.methods.farmerToFormation(parseInt(element.id)).call()
        .then(result => {
          if (result !== "0") setCrewInactive("crew")
        })
        .catch(err => console.log(err))

    }


  }, [])

  const objectItemsWaifuZero = {

    1: icon[4],
    2: icon[1],
    3: icon[5],
    4: icon[2],
    5: icon[3],
    6: icon[0],


  }

  useEffect(() => {
    if (element && element.waifuPowers) {


      if (harvestingMaterial || harvestingMaterial === 0) {
        setWaifuIcon(icon)
        return setWaifuPower(element.waifuPowers[harvestingMaterial])
      }


      let maxWaifuPower = Math.max(...element.waifuPowers)
      let tempAdn = parseInt(element.adn)

      let indexWaifuPower
      if (tempAdn < 6) indexWaifuPower = tempAdn - 1
      else
        indexWaifuPower = element.waifuPowers.indexOf(maxWaifuPower.toString())

      if (market && element.waifuPowers[0] === element.waifuPowers[1] && element.waifuPowers[0] === element.waifuPowers[2] && element.waifuPowers[0] === element.waifuPowers[3]) {
        indexWaifuPower = activeIcon
      }

      if (parseInt(element.adn) >= 0 && parseInt(element.adn) <= 6 && !market) setWaifuIcon(objectItemsWaifuZero[parseInt(element.adn)])
      else setWaifuIcon(icon[indexWaifuPower])

      setWaifuPower(maxWaifuPower)
    }
  }, [element])



  useEffect(() => {

    contract.contractWaifu.methods.ownerOf(element.id).call({ from: account }).then(result => setOwnerSell(result !== account ? null : true))

  }, [])



  const startArray = [start1, start2, start3, start4, start5]

  const startValue = () => {
    if (!element) return 0

    let value

    if (harvestingMaterial || harvestingMaterial === 0)
      value = element.waifuPowers[harvestingMaterial]
    else value = Math.max(...element.waifuPowers)

    if (harvestingMaterial || harvestingMaterial === 0)
      value = element.waifuPowers[harvestingMaterial]

    if (value <= 49) return 0
    else if (value <= 99) return 1
    else if (value <= 149) return 2
    else if (value <= 199) return 3
    else if (value <= 250) return 4
  }

  const background_time = (() => {
    if (farmTime) return night
    else return day
  })()

  const handleClickButton = () => {
    if (market) buyWaifu(element)
  }

  useEffect(() => {
    if (harvesting) {
      setHarvestCard("Select")
      selectWaifu.forEach(e => {
        if (e === parseInt(element.id)) {
          setHarvestCard("READY")
        }
      })
    }
  }, [autoSelectState, selectStartWaifu, selectWaifu])

  const harvestSelect = () => {
    if (harvesting) {
      handleSelectWaifu(element.id)
      setHarvestCard(haverstCard === "Select" ? "READY" : "Select")
    }
  }

  if (market && crewInactive === "crew") return null

  return (
    <CardFlip
      available={true}
      flip={flip}
      set_flip={setFlip}
      market={market}
      harvesting={haverstCard}
      inventory={inventory}
      crew={crew}
    >
      <div className="card_front" onClick={harvestSelect} aria-hidden="true">
        <div
          className={`relative hover:shadow-2xl ${!inventory &&
            !damanger &&
            !market &&
            haverstCard !== "READY" &&
            "grayscale"
            } cursor-pointer ease-in-out duration-300 rounded-b-lg  ${haverstCard === "READY" && "border-2 grayscale-0 border-[#61c739]"
            }`}
          style={{
            backgroundImage: `url(${background_time})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundPositionY: `${haverstCard || market ? "-80px" : "-80px"}`,
          }}
        >
          {crewInactive === "crew" && <div className="absolute -top-2 -left-2 w-1/2"><span className="absolute text-xl font-bold text-[white] top-6 left-3 -rotate-45">In crew</span><img src={ribbonCrew} /></div>}
          {element.forSale && ownerSell && <div className="absolute -top-2 -left-2 w-1/2"><span className="absolute text-xl font-bold text-[white] top-6 left-3 -rotate-45">Selling</span><img src={ribbonIcon} /></div>}
          {/* {licenceTime && <div className={`absolute ${market ? 'bottom-[4.5rem]' : 'bottom-14'} flex flex-row left-0 px-1 border-2 rounded-tr-full rounded-br-full text-[11px] border-[#006672] text-[#006672] bg-[#ABEEFC]`}><img className="w-4 mr-2" src={license_icon} alt="license icon" /> {licenceTime}</div>} */}
          {(creatingCrew || editingCrew) && <div className="w-14 h-6 flex rounded-xl border bg-white absolute top-2 left-2 justify-center items-center text-center"><span className="text-sm">ID: {element?.id}</span></div>}
          <img
            alt="start-img"
            src={startArray[startValue(element?.waifuPower) || 0]}
            className={`w-10 absolute top-2 right-2 `}
          />
          {!flip && (
            <>
              <div className={`w-full bg-fixed relative h-52`}>
                {haverstCard === "SELECT" && (
                  <div className="bg-[rgba(87,87,87,0.56)] absolute inset-0 z-[500]">
                    {" "}
                  </div>
                )}
                <GetImageAdn
                  genData={
                    element?.adn ||
                    "0003000300300030030003000300030030003003000300300030030030303"
                  }
                  mask={damanger}
                />
                <div className="absolute bottom-2 right-2 p-1 border-2 rounded-xl border-[#DB1A99] bg-[#F0C1DC]">
                  {element.durability > 100000
                    ? "Infinite"
                    : element.durability}
                </div>
              </div>
              <div
                className={`relative bg-backgroundOp text-center rounded-b-lg flex items-center flex-col justify-center  ${damanger ? "h-15" : !inventory ? "h-15" : "h-12"
                  }`}
              >
                <p className=" text-sm flex flex-row justify-center items-center">
                  {" "}
                  <strong> {waifuPower} WP </strong>
                  {waifuIcon && (
                    <img src={waifuIcon} alt="material" className="w-8 p-1 " />
                  )}{" "}
                </p>
                {!inventory && (
                  <button
                    onClick={handleClickButton}
                    className={`rounded-lg text-sm px-2 mb-1 ${haverstCard === "READY" ? "bg-[#61c739]" : "bg-[#D765A5]"} ${market ? "bg-[#AA2E74] text-white" : "text-white"} p-1`}
                  >
                    {" "}
                    <strong>
                      {" "}
                      {haverstCard ||
                        `${market
                          ? `${parseInt(element.sellPrice) /
                          parseInt(1 + "0".repeat(18))
                          } BNB `
                          : "HEAL"
                        }`}{" "}
                    </strong>{" "}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="card_back z-[23]">
        <div className="relative shadow-2xl bg-[#F8E6F1] rounded-b-lg overflow-hidden ">
          <div className="h-52 p-2 text-[#AA2E74] flex justify-center  relative">
            {farmTime && !damanger && farmTime.id === element.id && (
              <div className="absolute bottom-2 left-2 p-1 border-2 rounded-full text-[12px] border-[#006672] text-[#006672] bg-[#ABEEFC]">
                Farming Time: {farmTime.seconds}
              </div>
            )}

            <div className="flex flex-col">
              <div className="text-md font-semibold text-black text-center mb-1">
                Stats
              </div>
              <HexStats
                wp={element.waifuPowers}
                mint={mint}
                element={element}
                setModalNft={setModalNft}
                setModalNftBurn={setModalNftBurn}
                market={market}
              />
            </div>
          </div>
          <div
            className={`relative bg-backgroundOp text-center py-3 rounded-md rounded-b-lg h-16 flex items-center justify-center`}
          >
            <div className="absolute top-1/2 hover:top-5 text-[rgb(245,245,245)] hover:text-[#AA2E74] transition-all duration-300 -translate-y-1/2 left-2">
              {inventory && element && !element.forSale && (
                <div
                  onClick={() => {
                    /*if (!mint) {
                      setModalNftBurn({ item: element, type: "waifu" })
                    }*/
                  }}
                  className="mx-4  cursor-pointer h-7 w-7"
                  aria-hidden="true"
                >
                  <img
                    src={burn_icon}
                    className="w-7 h-7"
                    style={{
                      filter:
                        "invert(23%) sepia(80%) saturate(1396%) hue-rotate(294deg) brightness(95%) contrast(93%)",
                    }}
                    alt="burn-icon"
                  />
                  <div className="font-semibold leading-none">Burn</div>
                </div>
              )}
            </div>
            <p className="pb-2 text-md">
              {" "}
              <strong>ID:</strong> {element?.id || "000000"}{" "}
            </p>
            <div
              id="pruebatest"
              className={`absolute top-1/2 hover:top-5 text-[rgb(245,245,245)] hover:text-[#AA2E74] transition-all duration-300 -translate-y-1/2 ${element.forSale ? "right-10" : "right-6"
                }`}
            >
              {!market && inventory && (
                <div
                  onClick={() => {
                    if (!mint) setModalNft({ item: element, type: "waifu" })
                  }}
                  className=" cursor-pointer h-7 w-7 "
                  aria-hidden="true"
                >
                  <img
                    src={element && !element.forSale ? sell_icon : unsell_icon}
                    className={`w-7 h-7 ${element.forSale && "ml-2"}`}
                    style={{
                      filter:
                        "invert(23%) sepia(80%) saturate(1396%) hue-rotate(294deg) brightness(95%) contrast(93%)",
                    }}
                    alt="sell-icon"
                  />
                  <div className="font-semibold text-left">
                    {element && !element.forSale ? "Sell" : "Unsell"}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CardFlip>
  )
}

export default WaifuCard
