import React, { useContext, useState, useEffect } from "react"
import day from "../../assets/img/backgroundTractor/day.webp"
import afternoon from "../../assets/img/backgroundTractor/afternoon.webp"
import night from "../../assets/img/backgroundTractor/night.webp"
import dawn from "../../assets/img/backgroundTractor/dawn.webp"
import evening from "../../assets/img/backgroundTractor/evening.webp"
import tierOne from "../../assets/img/waifuTractor/TierOne.webp"
import tierTwo from "../../assets/img/waifuTractor/TierTwo.webp"
import tierThree from "../../assets/img/waifuTractor/TierThree.webp"
import tierFour from "../../assets/img/waifuTractor/TierFour.webp"
import tierFive from "../../assets/img/waifuTractor/TierFive.webp"

import start1 from "../../assets/vector/start1.svg"
import start2 from "../../assets/vector/start2.svg"
import start3 from "../../assets/vector/start3.svg"
import start4 from "../../assets/vector/start4.svg"
import start5 from "../../assets/vector/start5.svg"
import ribbonIcon from "../../assets/img/ribbonIcon.svg"
import burn_icon from "../../assets/vector/cards/burn.svg"
import sell_icon from "../../assets/vector/cards/sell.svg"
import unsell_icon from "../../assets/vector/cards/unsell.svg"

import CardFlip from "./card_flip"
import ribbonCrew from "../../assets/img/ribbonCrew.svg"
import UserContext from "../../context/userContext/UserContext"
const TractorCard = ({
  autoSelectState,
  selectStartTractor,
  handleSelectTractor,
  buyTractor,
  market,
  damanger,
  inventory,
  harvesting,
  element,
  setModalNft,
  setModalNftBurn,
  purchasable,
  selectTractor,
  crew, edit,
}) => {
  const { contract, account, chainId } = useContext(UserContext)
  const [haverstCard, setHarvestCard] = useState(harvesting)
  const [flip, setFlip] = useState(false)
  const [farmTime, setFarmTime] = useState(null)
  const [ownerSell, setOwnerSell] = useState(null)
  const [crewInactive, setCrewInactive] = useState(null)




  useEffect(() => {


    if (element.id) {

      contract.ContractCrew.methods.tractorToFormation(parseInt(element.id)).call()
        .then(result => {
          if (result !== "0") setCrewInactive("crew")
        })
        .catch(err => console.log(err))

    }


  }, [])

  useEffect(() => {
    let timeNow = new Date()
    let secondsLeft = element.availableFrom - timeNow.getTime() / 1000
    let intervalId

    if (secondsLeft > 0) {
      intervalId = setInterval(() => {
        if (secondsLeft > 0) {
          secondsLeft = secondsLeft - 1
          setFarmTime({
            id: element.id,
            seconds: new Date((secondsLeft || 0) * 1000)
              .toISOString()
              .substr(
                (secondsLeft || 0) < 3600 ? 14 : 11,
                (secondsLeft || 0) < 3600 ? 5 : 8
              ),
          })
        }
      }, 1000)
      setFarmTime({
        id: element.id,
        seconds: new Date((secondsLeft || 0) * 1000)
          .toISOString()
          .substr(
            (secondsLeft || 0) < 3600 ? 14 : 11,
            (secondsLeft || 0) < 3600 ? 5 : 8
          ),
      })
    } else setFarmTime(null)

    return () => clearInterval(intervalId)
  }, [element])

  useEffect(() => {
    if (harvesting) {
      setHarvestCard("Select")
      let findTractor = selectTractor.find(
        elementTwo => elementTwo === parseInt(element.id)
      )
      if (findTractor) setHarvestCard("READY")
    }
  }, [selectStartTractor])

  useEffect(() => {
    if (harvesting) {
      selectTractor.map(e => {
        if (e == element.id) {
          setHarvestCard("READY")
        }
      })
    }
  }, [autoSelectState, selectTractor])

  useEffect(() => {


    if (contract && element.id) {


      contract.contractTractor.methods.ownerOf(element.id).call({ from: account }).then(result => setOwnerSell(result !== account ? null : true))
    }

  }, [element.id])

  const backgroundArray = [day, afternoon, night, dawn, evening]
  const tractorArray = [tierOne, tierTwo, tierThree, tierFour, tierFive]
  const startArray = [start1, start2, start3, start4, start5]

  const background_time = (() => {
    if (farmTime) return night
    else return day
    /*    let hour = (new Date()).getHours();
           if (hour < 4) return night;
           if (hour < 8) return dawn;
           if (hour < 16) return day;
           if (hour < 18) return afternoon;
           if (hour < 20) return evening;
           if (hour <= 24) return night; */
  })()

  if (market && crewInactive === "crew") return null

  return (
    <CardFlip
      flip={flip}
      set_flip={setFlip}
      market={market}
      harvesting={haverstCard}
      inventory={inventory}
      crew={crew}
    >
      <div
        className="card_front"
        onClick={() => {
          if (
            harvesting &&
            ((!edit && selectTractor.length < 10 || (edit && edit < 10)) ||
              (selectTractor.length === 10 && haverstCard === "READY"))
          ) {
            handleSelectTractor(element.id)
            setHarvestCard(haverstCard === "Select" ? "READY" : "Select")
          }
        }}
      >
        <div
          className={`relative hover:shadow-2xl ${!inventory && !market && haverstCard !== "READY" && "grayscale"
            } cursor-pointer ease-in-out duration-300 rounded-b-lg  ${haverstCard === "READY" && "border-2 grayscale-0 border-[#61c739]"
            }`}
          style={{
            backgroundImage: `url(${background_time})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundPositionY: `${haverstCard || market ? "-80px" : "-50px"}`,
          }}
        >
          {crewInactive === "crew" && <div className="absolute -top-2 -left-2 w-1/2"><span className="absolute text-xl font-bold text-[white] top-6 left-3 -rotate-45">In crew</span><img src={ribbonCrew} /></div>}
          {element.forSale && ownerSell && <div className="absolute -top-2 -left-2 w-1/2"><span className="absolute text-xl font-bold text-[white] top-6 left-3 -rotate-45">Selling</span><img src={ribbonIcon} /></div>}
          <img
            alt="start-img"
            src={startArray[element?.level - 1 || 0]}
            className="w-10 absolute top-2 right-2 "
          />
          {!flip && (
            <>
              <div className="w-full bg-fixed relative h-52">
                <img
                  alt="waifu-img"
                  src={tractorArray[element?.level - 1 || 0]}
                  className="w-full pt-8"
                />
                <div className="absolute bottom-2 right-2 p-1 border-2 rounded-xl border-[#D9A900] bg-[#FFE68D]">
                  {element.durability}
                </div>
              </div>
              <div className={`text-md font-bold text-center ${damanger ? "h-16" : "h-[4rem]"} min-h-full flex flex-col items-center justify-center bg-white`}>
                <div>{element?.level} Seats</div>
                {damanger && (
                  <p className="text-md">
                    {" "}
                    <strong>
                      Status: <span className="text-red-500">Damaged</span>
                    </strong>{" "}
                  </p>
                )}
                {!inventory && (
                  <button
                    onClick={() => {
                      if (market) buyTractor(element)
                    }}
                    className={`rounded-lg text-md  ${haverstCard === "READY" ? "bg-[#61c739] text-white" : "bg-[#D9A900] text-black"} p-1`}
                  >
                    <strong>
                      {haverstCard ||
                        `${market
                          ? `${parseInt(element.sellPrice) /
                          parseInt(1 + "0".repeat(18))
                          } BNB`
                          : "HEAL"
                        }`}{" "}
                    </strong>{" "}
                  </button>
                )}
              </div>{" "}
            </>
          )}
        </div>
      </div>

      <div className="card_back">
        <div className="relative shadow-2xl bg-[#A8A9BA] rounded-b-lg overflow-hidden">
          <div className="relative h-52 p-2">
            {farmTime && !damanger && farmTime.id === element.id && (
              <div className="absolute top-2 left-2 p-1 border-2 rounded-full text-[12px] border-[#006672] bg-[#ABEEFC]">
                Farming Time: {farmTime.seconds}
              </div>
            )}
          </div>
          <div
            className={`relative bg-backgroundOp text-center py-3 rounded-md rounded-b-lg  ${damanger ? "h-24" : !inventory ? "h-24" : "h-14"
              } flex items-center justify-center`}
          >
            <div className="absolute top-1/2 hover:top-5 text-[rgb(245,245,245)] hover:text-[#000] transition-all duration-300 -translate-y-1/2 left-1">
              {element && !element.forSale && (
                <div
                  onClick={() => {
                    /*setModalNftBurn({ item: element, type: "tractor" })*/
                  }}
                  className="mx-4  transition-all duration-300 cursor-pointer h-7 w-7"
                >
                  <img
                    className="w-7 h-7"
                    src={burn_icon}
                    style={{
                      filter:
                        "invert(23%) sepia(5%) saturate(1715%) hue-rotate(195deg) brightness(100%) contrast(83%)",
                    }}
                    alt="burn-icon"
                  />
                  <div className="font-semibold flex justify-center">Burn</div>
                </div>
              )}
            </div>
            <div className="absolute top-1/2 hover:top-5 text-[rgb(245,245,245)] hover:text-[#000] transition-all duration-300 -translate-y-1/2 right-1">
              <div
                onClick={() => {
                  setModalNft({ item: element, type: "tractor" })
                }}
                className="mx-4  cursor-pointer h-7 w-7"
              >
                <img
                  className="w-7 h-7"
                  src={element && !element.forSale ? sell_icon : unsell_icon}
                  style={{
                    filter:
                      "invert(23%) sepia(5%) saturate(1715%) hue-rotate(195deg) brightness(100%) contrast(83%)",
                  }}
                  alt="sell-icon"
                />
                <div className="font-semibold flex justify-center">
                  {element && !element.forSale ? "Sell" : "Unsell"}
                </div>
              </div>
            </div>
            <p className="pb-2 text-md">
              {" "}
              <strong>ID:</strong> {element?.id || ""}{" "}
            </p>
          </div>
        </div>
      </div>
    </CardFlip>
  )
}

export default TractorCard
