import React, { useState } from "react";
const CardFlip = ({ crewSize, crew, children, available, inventory, market, harvesting, creating, flip, set_flip, license, selectHarvest }) => {
    const click_card = () => {
        if (market && !available || harvesting || selectHarvest || crew) return;

        if (!flip) return set_flip(true);
        set_flip(false)
    }
    return (
        <div className="m-4 " style={{ minHeight: inventory ? "250px" : "280px" }}>
            <div className={`card_item ${flip ? 'active flip' : ''}`} onCLick={click_card}>
                <div className={`card_content ${crewSize ? "w-[300px] h-[320px]" : ""}`}>
                    <div className="card_flip">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardFlip;