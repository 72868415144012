import React, { useEffect, useContext, useRef } from 'react';

import buttonClose from "../../../assets/vector/closeButton.svg";
import UserContext from "../../../context/userContext/UserContext";

const ModalX = ({ children, view, action, bg, btn_close, id, closeMint }) => {
    const { changeModal, changeModalBg } = useContext(UserContext);
    const modalRef = useRef();
    const close_hex_stats = () => {
        let item = (<></>);
        changeModal(item);
        changeModalBg(false);
        document.querySelector('body').classList.remove('modal-open');
    }

    const open_hex_stats = () => {
        let item = (
            <div className="modal fixed top-0 left-0 bottom-0 right-0 bg-gray-900 bg-opacity-60 flex justify-center items-center overflow-x-hidden overflow-y-auto z-10 outline-none" tabindex="-1" id={id} ref={modalRef}>
                {btn_close && <div onClick={() => action(false)}><img src={buttonClose} className="absolute right-2 top-2 hover:cursor-pointer h-12 md:h-16" alt="close-icon" /></div>}
                <div className="h-screen md:h-auto py-16 md:py-0">
                    {children}
                </div>
            </div>
        );
        changeModal(item);
        changeModalBg(true);
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('body').focus();
    }

    useEffect(() => {
        if (view) {
            open_hex_stats();
        } else if (modalRef.current) {
            let a = modalRef.current.getAttribute('id');
            if (a === id || closeMint) return close_hex_stats();
        }
    }, [view]);

    return;
}

export default ModalX;